import './Button.css'

export default function Button({ background = "clear", id, name, text, type = "button" }) {
  return (
    <button id={id}
      name={name}
      className={`button bg-${background}`}
      type={type}
    >
      {text}
    </button>
  )
}