export default function FestivalPast() {
  return (
    <div className="festival-past-container">
      <h2 className="past-festival-header">Past Festivals</h2>
      <table id="location-table">
        <trow className="location-table-row">
          <td>
            <div>Day 1</div>
            <h2 className="location-name">Washington Monroe Park</h2>
            <span className="festival-time"> Next to Revolution Hall - 1300 SE Stark St #111, Portland, OR 97214 (12pm-7pm)</span>
          </td>
        </trow>
        <trow className="location-table-row">
          <td>
            <div>Day 2</div>
            <h2 className="location-name">Montavilla Neighborhood</h2>
            <span className="festival-time">Plaza next to Bipartisan Cafe - SE 79th Ave & SE Stark St (12pm-6pm)</span>
            <p>Milwaukie Lumber Parking Lot - 8048 SE Stark St</p>
            <p>Beer Bunker - 7918 SE Stark St (3pm-6pm)</p>
          </td>
        </trow>
      </table>
    </div>
  )
}