import './AboutPrinciples.css'
import Button from '../Button'
import { Link } from 'react-router-dom'

export default function AboutPrinciples() {
  return (
    <div className="about-principles-container">

      <h2 className="resize-header" id="influences">
        Musical Influences
      </h2>

      <p className="about-text">
        Called everything from “avant-oompah!” to a “brassroots revolution,” Honk! bands draw inspiration from sources as diverse as Klezmer, Balkan and Romani music, Brazilian Samba, Afrobeat and Highlife, Punk, Funk, and Hip Hop, as well as the New Orleans second line tradition, and deliver it with all the passion and spirit of Mardi Gras and Carnival.
      </p>

      <h2 className="resize-header" id="principles">
        Principles of Unity
      </h2>

      <ul className="about-principles-list">
        <li>
          <em>We seek to build a community of activist street bands</em>
          that engages progressive issues through music, and spectacle, and that comestogether to share knowledge and experience, and to support each other.
        </li>
        <li>
          <em>We believe that music in the streets helps</em>
          to reclaim and redefine the use of public space.
        </li>
        <li>
          <em>We believe that music brings elements of power, energy, and healing</em>
          to struggles for collective liberation.
        </li>
        <li>
          <em>We stand in solidarity</em>
          with struggles that challenge all forms of individual and institutional domination, inequality,and oppression, based on class, race, gender, sexual preference, age, ethnicity, and/or ability.
        </li>
        <li>
          <em>Because we believe that lasting change begins at the grassroots;</em>
          we prioritize democratically organized events and struggles that promote broad-based coalitionand movement building, and oppose those that encourage divisiveness and disunity.
        </li>
        <li>
          <em>Because we believe that the capitalist drive for profit promotes inequality</em>
          of wealth, material waste, and human disregard, we emphasize events that are non-commercial,environmentally friendly, community-oriented, and physically accessible.
        </li>
        <li>
          <em>We acknowledge that the struggle for broad collective liberation</em>
          begins with transforming ourselves and our own community.
        </li>
        <li>
          <em>We strive to build democratic leadership;</em>
          particularly among populations that are and have been most directly affected by oppressionand inequality. And we recognize that there is a lot of work to be done in this area.
        </li>
        <li>
          <em>Within our HONK! Festival and events, we aspire to build a “Brave Space” –</em>
          a supportive environment in which we take on controversial issues with each other, and engagein disagreement and discomfort with each other, knowing that doing so will help us build deeper and more trustful relationships.
        </li>
        <li>
          <em>We believe it is important to acknowledge and learn from differences</em>
          which arise between our intentions and our impacts.
        </li>
        <li>
          <em>We wish for HONK! to be a community in which we uplift each other;</em>
          and give each other energy to continue fighting for collective liberation.
        </li>
      </ul>

      <Link to="/connect">
        <Button background="indigo" text="SUBSCRIBE FOR UPDATES" />
      </Link>

    </div>
  )
}