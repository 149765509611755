import FooterBar from "../../components/FooterBar";
import NavBar from "../../components/NavBar";
import VolunteerHero from "../../components/VolunteerHero";

export default function Volunteer() {
  return (
    <div className="volunteer-page">
      <header>
        <NavBar />
      </header>

      <main>
        <VolunteerHero />
      </main>

      <footer>
        <FooterBar />
      </footer>
    </div>
  )
}
