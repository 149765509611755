import './ConnectForm.css'
import Button from '../Button'

export default function ConnectForm() {
  return (
    <div>
      <form action="https://gmail.us21.list-manage.com/subscribe/post?u=c9ccbc43dac9c74896a40201d&amp;id=726e030336&amp;f_id=0072c0e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" novalidate="novalidate">
        <div className="form-container">
          <div className="connect-form radio-container">
            <p>Subscribe for updates and join the Honk! movement</p>
            <div>
              <div>
                <Button background="nature" submitButton={true} text="Submit" name="subscribe" id="mc-embedded-subscribe" type="submit" />
              </div>
            </div>
          </div>
          <div id="mc_embed_signup_scroll" className="connect-form text-input-container">
            <div className="required">* indicates required</div>
            <div>
              <label htmlFor="mce-EMAIL">Email Address <span>*</span>
              </label>
              <input type="email" name="EMAIL" id="mce-EMAIL" required="" aria-required="true" />
              <span id="mce-EMAIL-HELPERTEXT"></span>
              <div data-lastpass-icon-root="true"></div></div>
            <div>
              <label htmlFor="mce-FNAME">First Name </label>
              <input type="text" name="FNAME" id="mce-FNAME" />
              <span id="mce-FNAME-HELPERTEXT"></span>
            </div>
            <div>
              <label htmlFor="mce-LNAME">Last Name </label>
              <input type="text" name="LNAME" id="mce-LNAME" />
              <span id="mce-LNAME-HELPERTEXT"></span>
            </div>
            <div id="mce-responses">
              <div id="mce-error-response"></div>
              <div id="mce-success-response"></div>
            </div>
            <div aria-hidden="true"><input className="input-helper" type="text" name="b_c9ccbc43dac9c74896a40201d_726e030336" tabindex="-1" /></div>
          </div>
        </div>
      </form>
    </div>
  )
}