import './CallToAction.css'
import Button from '../Button'
import { Link } from 'react-router-dom'


export default function CallToAction() {
  return (
    <div id="cta">
      <h1>
        What is HONK?!
      </h1>
      <p>
        An irresistible spectacle of creative expression—unifying people and reclaiming public space through the joy of music.
      </p>
      <p>
        Played among the people, for the people, Honk! invites us to all join together in making the world a better place.
      </p>
      <div className="cta-button-group">
        <Link to="/connect">
          <Button background="brass" text="SUBSCRIBE" />
        </Link>
        <Link to="/about">
          <Button text="LEARN MORE" />
        </Link>
      </div>
    </div>
  )
}