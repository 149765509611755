import './FestivalHero.css'
import FestivalLogoSVG from '../../media/svg/Honk_People_Web_small_header.svg'
import LentsParkMapPNG from '../../media/png/Lents-Park-Map.png'
import LentsParkSchedulePNG from '../../media/png/Lents-Park-Schedule.png'
import MontavillaMapPNG from '../../media/png/Montavilla-Map.png'
import MontavillaSchedulePNG from '../../media/png/Montavilla-Schedule.png'

export default function FestivalHero() {

  return (
    <div className="festival-hero-container">
      <div className="bg-community">
        <img alt="Honk Fest, June 8th and 9th, 2024" src={FestivalLogoSVG} />
        <h1 id="festival-header">HONK! PDX (JUNE 8-9 2024)</h1>
      </div>
      <div className="location-and-schedule-container">
        <div className="festival-image-container">
          <img alt="Lents Park map" src={LentsParkMapPNG} />
        </div>
        <div className="festival-image-container">
          <img alt="Schedule for Saturday at Lents Park" src={LentsParkSchedulePNG} />
        </div>
        <div className="festival-image-container">
          <img alt="Montavilla map" src={MontavillaMapPNG} />
        </div>
        <div className="festival-image-container">
          <img alt="Schedule for Sunday at Montavilla" src={MontavillaSchedulePNG} />
        </div>
      </div>
      <div className="detail-container">
        <p><b>Cost:</b> FREE - Donations gladly accepted!</p>
        <p><b>Saturday location:</b>  South end of Lents Park
          <div><em>SE 92nd Ave & SE Steele St</em></div>
          1pm-6pm
        </p>
        <p><b>Sunday location:</b> Montavilla Neighborhood
          <div>Plaza at <em>SE Stark St & SE 79th St</em></div>
          <div>Milwaukie Lumber Parking Lot, <em>SE 81st & SE Washington St</em></div>
          <div>Beer Bunker, <em>7918 SE Stark St</em></div>
          12pm-7pm
        </p>
        <p><b>What to bring:</b> Chairs, picnic blankets, water, snacks, sunscreen, an instrument, hula hoops, flow toys, bubbles, and most importantly your dancing shoes!</p>
        <p>Well-behaved, leashed dogs welcome. Loud noises and lots of people can be overwhelming for some dogs, please keep this in mind if you would like to bring your furry friend.</p>
        <p><b>More information to come!</b></p>
      </div>
    </div>
  )
}