import NavBar from "../../components/NavBar"
import SponsorsHero from "../../components/SponsorsHero"
import FooterBar from "../../components/FooterBar"

export default function Sponsors() {
  return (
    <div className="sponsors-page">
      <header>
        <NavBar />
      </header>

      <main>
        <SponsorsHero />
      </main>

      <footer>
        <FooterBar />
      </footer>
    </div>
  )
}