import './VolunteerHero.css'
import Button from '../Button'
import volunteerHeroSVG from '../../media/svg/Honk_People_Web_small_header.svg'

export default function VolunteerHero() {
  return (
    <div className="volunteer-hero-container">
      <div className="bg-community">
        <img alt="" className="bg-community" src={volunteerHeroSVG} />
        <h1>Volunteer</h1>
      </div>

      <div className="location-table-container">
        <table id="location-table">
          <trow className="location-table-row">
            <td>
              <div></div>
              <h2 className="location-name">Honk! PDX 2024</h2>
              <a href="https://forms.gle/umAsT7qCuSGJYyyY6"
                target="_blank"
                rel="noreferrer"
              >
                <Button background="brass" text="SIGN UP HERE" />
              </a>
            </td>
          </trow>
        </table>
      </div>
    </div>
  )
}