import homeHeroSVG from '../../media/svg/Honk_Fest_Logo.svg'
import './HomeHero.css'

export default function HomeHero() {
  return (
    <div id="hero">
      <div className="hero-container">
        <iframe className="hero-iframe" src="https://www.youtube.com/embed/7nj7LZAACtA" title="HONK! PDX 2024 - WHOOP BRASS!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className="hero-container hero-svg-container">
        <img src={homeHeroSVG} alt="Honk Fest, July 22nd and 23rd" />
      </div>
    </div>
  )
}