import './HomeMedia.css'

export default function HomeMedia() {
  return (
    <div id="home-media">
      <div id="home-video-container">
        <h2>See our past performances</h2>
        <span className="home-video">
          <iframe src="https://www.youtube.com/embed/j12dRGScR-o?si=LEHV3qYvhD0QXWPe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </span>
        <span className="home-video">
          <iframe src="https://www.youtube.com/embed/KUGndPza73Q?si=Q4dO24BxPE6ZO2IS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </span>
        <span className="home-video">
          <iframe src="https://www.youtube.com/embed/VAQPD8a42xw?si=1CuRtLrCpPyYL5ll" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </span>
        <span className="home-video">
          <iframe src="https://www.youtube.com/embed/OcWdgrHdhog?si=wy3z_spn_KPAg6AY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </span>
      </div>
      <div id="home-link-container">
        <h2>Honk! PDX in the media</h2>
        <p className="home-link-entry">
          <a className="home-link" href="https://prp.fm/community-voices-featuring-honkpdx/">
            Community Voices featuring HONK! PDX
          </a>
          <div className="media-name">- Portland Radio Project</div>
        </p>
        <p className="home-link-entry">
          <a className="home-link" href="https://www.opb.org/article/2023/07/21/honk-pdx-street-music-fest-portland/">
            First HONK! PDX brings joyful noise of brass, street bands to Portland neighborhoods
          </a>
          <div className="media-name">- Portland Public Broadcasting</div>
        </p>
        <p className="home-link-entry">
          <a className="home-link" href="https://eastpdxnews.com/general-news-features/honk-takes-over-montavilla/">
            HONK! takes over Montavilla
          </a>
          <div className="media-name">- East PDX News</div>
        </p>
        <p className="home-link-entry">
          <a className="home-link" href="https://metba.org/me-calendar/honkpdx-in-the-plaza/">
            Honk! PDX in the plaza
          </a>
          <div className="media-name">- Montavilla/East Tabor Business Association</div>
        </p>
      </div>
      <div id="home-links-container">

      </div>
    </div>
  )
}