import './AboutHero.css'
import aboutHeroBannerSVG from '../../media/svg/Honk_People_Web_header_history.svg'
export default function AboutHero() {


  return (
    <div className="about-hero">
      <img alt="" className="bg-community" id="about-banner" src={aboutHeroBannerSVG} />
      <div className="about-container">
        <h1 className="resize-header">Played among the people, for the people.</h1>

        <div className="about-text">
          <p>
            Outrageous and inclusive, brass and brash, percussive and persuasive — Honk! music is spreading across the globe, reclaiming public space with a sound that is in your face. At full power, Honk! brass bands are an irresistible spectacle of creative movement and sonic self-expression.
          </p>
          <p>
            HONK! PDX is a free celebration of community, collaboration and creative expression and is dedicated to creating a community of inclusion. We are working to break down systemic barriers for the underrepresented within our community, and increase community access by producing the festival in neighborhoods of the Portland area underserved by the arts.
          </p>
          <p>
            Our interactive celebrations are designed to be accessible, with representation from a wide range of ages and abilities; offering pick-up bands throughout the festival open for anyone who would like to pick up an instrument and play.
          </p>
          <p>
            Began in Massachusetts in 2008, HONK! has expanded to New York, Detroit, Seattle, Austin, Brazil and Australia and now Portland, Oregon in 2023!
          </p>
        </div>
      </div>

    </div>
  )
}