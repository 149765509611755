import './FestivalBandList.css'

export default function FestivalBandList({header, bandList}) {

  const bandRows = bandList.map(band =>
    <tr className="band-table-row">
      <td className='band-table-entry'>
        <div className="band-name">
          <h3>{band["NAME"]}</h3>
        </div>
        <span className="band-city">
          ({band["CITY_STATE"]})
        </span>
      </td>
    </tr>
  )

  return (
    <div className="band-container bg-community">
      <h1 className="band-header resize-header">{header}</h1>
      <table className="band-table">
        {bandRows}
      </table>
    </div>
  )
}