import './SponsorsHero.css'
import AdxPNG from '../../media/png/ADX_wordmark-hero.png'
import MetbaPNG from '../../media/png/metba-logo.jpg'
import MrpPNG from '../../media/png/mr-plywood.png'
import PdxEventsFilm from '../../media/png/portland-events-and-film.png'
import RaccJPG from '../../media/png/racc.jpg'
import SeUpliftPNG from '../../media/png/SEUL_logo_blue.png'
import SponsorHeroSVG from '../../media/svg/Honk_People_Web_header_supporters.svg'
import TotalPromoPNG from '../../media/png/total-promotions.png'
import TrimetPNG from '../../media/png/TriMet-Slogan-Web.png'

export default function SponsorsHero() {
  return (
    <div className="sponsor-hero-container">
      <h1 className="hidden">
        Honk! Supporters
      </h1>
      <img alt="" className="bg-community" src={SponsorHeroSVG} />
      <div className="sponsor-card">
        <img alt="Portland Events & Film" 
          className="sponsor-logo" src={PdxEventsFilm} />
        <span className="sponsor-title">
          <h2>Portland Events & Film</h2>
          <em>Portland Events and Film is proud to support events that celebrate our city, build community, and represent the diversity of Portland’s creative talent, businesses, and communities.</em>
        </span>
      </div>
      <div className="sponsor-card">
        <img alt="Southeast Uplift Neighborhood Coalition" 
          className="sponsor-logo" src={SeUpliftPNG} />
        <span className="sponsor-title">
          <h2>SE Uplift Neighborhood Coalition</h2>
          <em>(fiscally sponsored)</em>
        </span>
      </div>
      <div className="sponsor-card">
        <img alt="Art Design Xchange" 
          className="sponsor-logo" src={AdxPNG} />
        <span className="sponsor-title">
          <h2>Art Design Xchange</h2>
          <em>ADX is where artists, designers, makers, builders, thinkers, and innovators come together to create, connect, and collaborate. 
            We call this creative Xchange, and it is fundamental to everything we do. 
            We offer a variety of opportunities to get involved, and we are here to empower creative Xchange for everyone who wants to participate. 
            Check out our membership options, classes, and public events below. 
            We can’t wait to see what you bring to the community!
          </em>
        </span>
      </div>
      <div className="sponsor-card">
        <img alt="Regional Arts and Culture Council" 
          className="sponsor-logo" src={RaccJPG} />
        <span className="sponsor-title">
          <h2>Regional Arts and Culture Council</h2>
          <em>HONK! PDX is funded in part by the Regional Arts & Culture Council.</em>
        </span>
      </div>
      <div className="sponsor-card">
        <img alt="Family Style Customer Service
Delivery Service • Custom Cutting • Special Orders
7609 S. E. Stark Sreet • Portland, OR 97215
Phone: 503.254.7387 • Fax: 503.254.7663
www.mrolywoodinc.com" className="sponsor-logo" src={MrpPNG} />
        <span className="sponsor-title">
          <h2>Mr Plywood Building Materials</h2>
        </span>
      </div>
      <div className="sponsor-card">
        <img alt="Total Promotions, Inc.
Branding your future" className="sponsor-logo" src={TotalPromoPNG} />
        <span className="sponsor-title">
          <h2>Total Promotions, Inc.</h2>
        </span>
      </div>
      <div className="sponsor-card">
        <img alt="Montavilla East Tabor Business Association" className="sponsor-logo" src={MetbaPNG} />
        <span className="sponsor-title">
          <h2>Montavilla/East Tabor Business Association (METBA)</h2>
        </span>
      </div>
      <div className="sponsor-card">
        <img alt="TriMet" className="sponsor-logo" src={TrimetPNG} />
        <span className="sponsor-title">
          <h2>TriMet</h2>
        </span>
      </div>
    </div>
  )
}