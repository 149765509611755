import './FooterBar.css'
import { Link } from 'react-router-dom';

export default function FooterBar() {
  return (
    <div id="footer">
      <div className="footer-container">
        <ul className="footer-section">
          <li className="footer-section-title">What is Honk!</li>
          <li><Link to='/about#influences'>Musical Influences</Link></li>
          <li><Link to='/about#principles'>Principles of Unity</Link></li>
        </ul>
        <ul className="footer-section">
          <li className="footer-section-title">HONK! PDX</li>
          <li><Link to='/festival#bands'>Bands & Line-up</Link></li>
          <li><Link to='/festival#map'>Festival Map</Link></li>
          <li><Link to='/festival#details'>Event Details</Link></li>
        </ul>
        <ul className="footer-section">
          <li className="footer-section-title">Stay Connected</li>
          <li><Link to='/connect'>Subscribe for Updates</Link></li>
          <li><Link to='/festival'>Blog</Link></li>
          <li><Link to='#'>#Honk-PDX</Link></li>
        </ul>
        <ul className="footer-section">
          <li className="footer-section-title">Support Honk!</li>
          <li><Link to='/volunteer'>Volunteer</Link></li>
          <li>
            <a href="https://secure.givelively.org/donate/southeast-uplift-neighborhood-program-inc/honk-pdx?fbclid=IwAR3jImim6L6PLUMu8C0ww5EdM7C6X6xVVzgUtHLQDcZbUxLqywWh5sAouIA"
              target="_blank"
              rel="noreferrer"
            >
              Donate
            </a>
          </li>
          <li><Link to='/sponsors'>Sponsors</Link></li>
        </ul>
      </div>
    </div>
  );
}