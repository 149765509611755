import './AboutTeam.css'

export default function AboutTeam() {
  return (
    <div id="about-team-container">
      <h2>Meet the Team!</h2>
      <ul id="team-list">
        <li className="team-member-entry">
          <span className="team-member-title">Principal Coordinator: </span>
          <span className="team-member-name">
            Liz Kasser <span className="pronouns">(they/she)</span>
          </span>
        </li>
        <li className="team-member-entry">
          <span className="team-member-title">Fundraising Assistant: </span>
          <span className="team-member-name">
            Corrina Chase <span className="pronouns">(she/her)</span>
          </span>
        </li>
        <li className="team-member-entry">
          <span className="team-member-title">Volunteer Coordinator: </span>
          <span className="team-member-name">
            Adrian Leyva <span className="pronouns">(they/he)</span>
          </span>
        </li>
        <li className="team-member-entry">
          <span className="team-member-title">Band Liason: </span>
          <span className="team-member-name">
            Xander Simmons <span className="pronouns">(they/them)</span>
          </span>
        </li>
        <li className="team-member-entry">
          <span className="team-member-title">Outreach: </span>
          <span className="team-member-name">
            Ray Austin <span className="pronouns">(they/them)</span>
          </span>
        </li>
        <li className="team-member-entry">
          <span className="team-member-title">Operations: </span>
          <span className="team-member-name">
            Ripley <span className="pronouns">(he/him)</span>
          </span>
        </li>
        <li className="team-member-entry">
          <span className="team-member-title">Website: </span>
          <span className="team-member-name">
            Berto Moore <span className="pronouns">(he/him)</span>
          </span>
        </li>
        <li className="team-member-entry">
          <span className="team-member-title">Food Lead: </span>
          <span className="team-member-name">
            Kelly Waters <span className="pronouns">(she/her)</span>
          </span>
        </li>
        <li className="team-member-entry">
          <span className="team-member-title">Communications: </span>
          <span className="team-member-name">
            Kimberley McCain <span className="pronouns">(she/her)</span>
          </span>
        </li>
        <li className="team-member-entry">
          <span className="team-member-title">Treasurer: </span>
          <span className="team-member-name">
            Kelly Faddis <span className="pronouns">(she/her)</span>
          </span>
        </li>
      </ul>
    </div>
  )
}