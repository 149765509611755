import AboutHero from '../../components/AboutHero';
import AboutPrinciples from '../../components/AboutPrinciples';
import AboutSupport from '../../components/AboutSupport'
import AboutTeam from '../../components/AboutTeam'

import FooterBar from '../../components/FooterBar';
import NavBar from '../../components/NavBar';

export default function About() {
  return (
    <div className="about-page">
      <header>
        <NavBar />
      </header>

      <main>
        <AboutHero />
        <AboutSupport />
        <AboutPrinciples />
        <AboutTeam />
      </main>

      <footer>
        <FooterBar />

      </footer>
    </div>
  )
}