import NavBar from '../../components/NavBar';
import FooterBar from '../../components/FooterBar';

import FestivalHero from '../../components/FestivalHero';
import FestivalBandList from '../../components/FestivalBandList';
import FestivalPast from '../../components/FestivalPast';

const bandList2023 = [
  {
    "NAME": "Tacomarama",
    "CITY_STATE": "Tacoma, WA"
  },
  {
    "NAME": "Chaotic Noise Marching Corps",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Neon Brass Party",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Filthy FemCorps",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Madband",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "Beat Crunchers",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "Banda Arrasta!",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Brassless Chaps",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Bloco Alegria, Portland Samba",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Unpresidented Brass Band",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "The Beat Goes On Marching Band",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Couch Brass",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Last Regiment of Syncopated Drummers",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Chervona",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "The Jesters",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Power Pep Band",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Bug Complex",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "One Love",
    "CITY_STATE": "Seattle, WA"
  },
]

const bandList2024 = [
  {
    "NAME": "Analog Brass",
    "CITY_STATE": "Bellingham, WA"
  },
  {
    "NAME": "Artesian Rumble Arkestra",
    "CITY_STATE": "Olympia, WA"
  },
  {
    "NAME": "Beat Crunchers",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "Brassless Chaps",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Bug Complex",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Chaotic Noise Marching Corps",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Cimarrona La Original Domingueña",
    "CITY_STATE": "Costa Rica"
  },
  {
    "NAME": "Couch Brass",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "8-Bit Brass Band",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "The Fighting Instruments of Karma Marching Chamber Band/Orchestra",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "Filthy FemCorps",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Jesters Brass Marching Band",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Kef",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "MADBand",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "Neon Brass Party",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Portland Samba",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Portland Taiko",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "The Power Pep Band",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Tacomarama Community Street Band",
    "CITY_STATE": "Tacoma, WA"
  },
  {
    "NAME": "Unpresidented Brass Band",
    "CITY_STATE": "Portland, OR"
  }
]

export default function Festival() {
  return (
    <div className="App">
      <header>
        <NavBar />
      </header>

      <main>
        <FestivalHero />
        <FestivalBandList header="Honk! PDX 2024 Festival Bands" bandList={bandList2024} />
        <FestivalPast />
        <FestivalBandList header="Honk! PDX 2023 Festival Bands" bandList={bandList2023} />
      </main>

      <footer>
        <FooterBar />

      </footer>
    </div>
  )
}