import './AboutSupport.css'
import Button from '../Button'

export default function AboutSupport() {
  return (
    <div className="about-support-container bg-nature">
      <h2 className="resize-header">
        HONK! is coming to Portland, OR
      </h2>

      <a href="https://secure.givelively.org/donate/southeast-uplift-neighborhood-program-inc/honk-pdx?fbclid=IwAR3jImim6L6PLUMu8C0ww5EdM7C6X6xVVzgUtHLQDcZbUxLqywWh5sAouIA" 
          target="_blank"
          rel="noreferrer"
        >
          <Button background="brass" text="SUPPORT HONK!" />
        </a>
    </div>
  )
}