import './HomeSupport.css'
import Button from '../Button'
import homeSupportSVG from '../../media/svg/Honk_People_Web_footer.svg'

export default function HomeSupport() {
  return (
    <div className="bg-community home-support-container">
      <h2>
        HONK! is coming to Portland, OR
      </h2>
      <p>
        Support us by donating online or by texting "HONK PDX" to 44-321
      </p>
      <a href="https://secure.givelively.org/donate/southeast-uplift-neighborhood-program-inc/honk-pdx?fbclid=IwAR3jImim6L6PLUMu8C0ww5EdM7C6X6xVVzgUtHLQDcZbUxLqywWh5sAouIA"
        target="_blank"
        rel="noreferrer"
      >
        <Button background="nature" text="SUPPORT HONK!" />
      </a>
      <img alt="" src={homeSupportSVG} />
    </div>
  )
}