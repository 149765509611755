import CallToAction from '../../components/CallToAction';
import HomeHero from '../../components/HomeHero';
import HomeMedia from '../../components/HomeMedia';
import HomeSupport from '../../components/HomeSupport';
import NavBar from '../../components/NavBar';
import FooterBar from '../../components/FooterBar';


export default function Home() {
  return (
    <div className="App">
      <header>
        <NavBar />
        <HomeHero />
      </header>

      <main>
        <CallToAction />
        <HomeMedia />
        <HomeSupport />
      </main>

      <footer>
        <FooterBar />

      </footer>
    </div>
    )
}