import NavBar from '../../components/NavBar';
import FooterBar from '../../components/FooterBar';

import ConnectHero from '../../components/ConnectHero';

export default function Connect() {
  return (
    <div className="App">
      <header>
        <NavBar />
      </header>

      <main>
        <ConnectHero />
      </main>

      <footer>
        <FooterBar />

      </footer>
    </div>
    )
}